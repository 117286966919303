import store from "@/store";

const storageKey = "token";

export const authenticate = () => {
  const { jwt } = store.state.auth;
  if (jwt) return true;

  return isExistJwt();
};

export const authSetUp = (tokenOnStore) => {
  if (tokenOnStore) return { jwt: tokenOnStore };

  const localJwt = loadJwt();
  if (!localJwt) return "";

  const json = JSON.parse(localJwt);
  return json;
};

export const isExistJwt = () => {
  return storageKey in localStorage;
};

export const loadJwt = () => {
  return localStorage.getItem(storageKey);
};

export const saveJwt = (data) => {
  localStorage.setItem(storageKey, JSON.stringify(data));
};

export const clearJwt = () => {
  localStorage.clear();
};

export const hasAuthInfo = ({ authenticated }) => {
  return authenticated;
};
