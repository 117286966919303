import { AmountOfMoney } from "../values/amount_of_money";
import { Date } from "@/model/values/date";
import { toSnake } from "@/service/convert_service";

const properties = {
  type: { id: "", name: "" },
  adjustmentAmount: AmountOfMoney.empty(),
  adjustmentComment: "",
  adjustedAt: Date.empty(),
};

export class BillingAdjustment {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(items = properties) {
    return new BillingAdjustment({ ...properties, ...items });
  }
  static empty() {
    return BillingAdjustment.valueOf({});
  }

  toJson() {
    const { type, adjustmentAmount, adjustmentComment } = this;

    const params = {
      type: type.id,
      adjustmentAmount: adjustmentAmount.value,
      adjustmentComment,
    };

    return toSnake(params);
  }

  updateType(value) {
    const properties = { ...this, ...{ type: value } };
    return BillingAdjustment.valueOf(properties);
  }
  updateAdjustmentAmount(value) {
    const properties = {
      ...this,
      ...{
        adjustmentAmount: isFinite(value)
          ? AmountOfMoney.valueOf(Number(value))
          : value,
      },
    };
    return BillingAdjustment.valueOf(properties);
  }
  updateAdjustmentComment(value) {
    const properties = { ...this, ...{ adjustmentComment: value } };
    return BillingAdjustment.valueOf(properties);
  }
}
