import { toSnake } from "@/service/convert_service";
import { Permission } from "../values/permission";
import { AccountStatus } from "./account_status";

const properties = {
  mftId: 0,
  email: "",
  lastName: "",
  firstName: "",
  lastNameKana: "",
  firstNameKana: "",
  employeeNumber: "",
  departmentName: "",
  cellphoneNumber: "",
  status: AccountStatus.empty(),
  permission: Permission.empty(),
};

export class Account {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(items = properties) {
    return new Account({ ...properties, ...items });
  }
  static empty() {
    return Account.valueOf({});
  }

  toJson() {
    const {
      lastName,
      firstName,
      lastNameKana,
      firstNameKana,
      permission,
      employeeNumber,
      departmentName,
      cellphoneNumber,
    } = this;

    const params = {
      lastName,
      firstName,
      lastNameKana,
      firstNameKana,
      employeeNumber,
      departmentName,
      cellphoneNumber,
      permission: {
        functions_permission: permission.functionPermissions,
      },
    };

    return toSnake(params);
  }

  toString() {
    return `${this.lastName || ""} ${this.firstName || ""}`;
  }

  updateFirstName(value) {
    const properties = { ...this, ...{ firstName: value } };
    return Account.valueOf(properties);
  }
  updateFirstNameKana(value) {
    const properties = { ...this, ...{ firstNameKana: value } };
    return Account.valueOf(properties);
  }
  updateLastName(value) {
    const properties = { ...this, ...{ lastName: value } };
    return Account.valueOf(properties);
  }
  updateLastNameKana(value) {
    const properties = { ...this, ...{ lastNameKana: value } };
    return Account.valueOf(properties);
  }
  updateEmail(value) {
    const properties = { ...this, ...{ email: value } };
    return Account.valueOf(properties);
  }
  updateEmployeeNumber(value) {
    const properties = { ...this, ...{ employeeNumber: value } };
    return Account.valueOf(properties);
  }
  updateDepartmentName(value) {
    const properties = { ...this, ...{ departmentName: value } };
    return Account.valueOf(properties);
  }
  updateCellphoneNumber(value) {
    const properties = { ...this, ...{ cellphoneNumber: value } };
    return Account.valueOf(properties);
  }
  updateFunctionPermission(value) {
    const permission = this.permission.updateFunctionPermission(value);
    const properties = { ...this, ...{ permission } };
    return Account.valueOf(properties);
  }
  updateFunctionPermissions(value) {
    const permission = this.permission.updateFunctionPermissions(value);
    const properties = { ...this, ...{ permission } };
    return Account.valueOf(properties);
  }
  updateAllFunctionPermissions(value) {
    const permission = this.permission.updateAllFunctionPermissions(value);
    const properties = { ...this, ...{ permission } };
    return Account.valueOf(properties);
  }
  updateClearFunctionPermissions() {
    const permission = this.permission.updateClearFunctionPermissions();
    const properties = { ...this, ...{ permission } };
    return Account.valueOf(properties);
  }

  get functionPermissions() {
    return this.permission.functionPermissions;
  }

  get accountNameFull() {
    return `${this.lastName || ""} ${this.firstName || ""}（${
      this.lastNameKana || ""
    } ${this.firstNameKana || ""}）`;
  }
}
