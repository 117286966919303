import { examinationMaterialFromJson } from "@/dto/member";
import Repository from "@/infrastructure/api";
import { toCamel } from "@/service/convert_service";

const resource = "management/examination_materials";

export class ExaminationMaterialRepository {
  fileDownload = async (id) => {
    const response = await Repository.get(`${resource}/${id}`, {
      responseType: "blob",
    });
    return new Blob([response.data], {
      type: response.headers["content-type"],
    });
  };

  fileUpload = async (payload) => {
    const { data } = await Repository.post(`${resource}`, payload);
    const material = toCamel(data);
    return examinationMaterialFromJson({
      ...material,
      fileName: material.materialType,
    });
  };
}
