import { Date } from "@/model/values/date";
import { AmountOfMoney } from "../values/amount_of_money";
import { UsageStatus } from "./usage_status";

const properties = {
  useAt: Date.empty(),
  type: "",
  toMemberId: "",
  toMemberName: "",
  amount: AmountOfMoney.empty(),
  prefix: "",
  fee: AmountOfMoney.empty(),
  balance: AmountOfMoney.empty(),
  status: UsageStatus.empty(),
  errorReason: "",
};

export class UsageLog {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(items = properties) {
    return new UsageLog({ ...properties, ...items });
  }
  static empty() {
    return UsageLog.valueOf({});
  }
}
