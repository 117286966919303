export class AmountOfMoney {
  constructor(value) {
    this.value = value;
  }

  static empty() {
    return AmountOfMoney.valueOf(null);
  }
  static valueOf(value = 0) {
    return new AmountOfMoney(value);
  }

  updateValue(value) {
    if (!value) return AmountOfMoney.empty();
    return new AmountOfMoney(value);
  }

  // valueOfの引数をobjectにする
  static copyWith({ value }) {
    return AmountOfMoney.valueOf(value);
  }

  toString() {
    if (!this.value && this.value !== 0) return "";
    return `¥${Number(this.value).toLocaleString()}`;
  }

  get isExist() {
    return this.value;
  }
}
