import { toSnake } from "@/service/convert_service";

const properties = {
  functionPermissions: [],
};

export class Permission {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static empty() {
    return Permission.valueOf({});
  }

  static valueOf(item = properties) {
    return new Permission({ ...properties, ...item });
  }

  updateFunctionPermission(value) {
    const functionPermissions = this.newPermissions(value);
    return Permission.valueOf({ ...this, functionPermissions });
  }

  updateFunctionPermissions(value) {
    const permissions = this.functionPermissions;
    let functionPermissions;
    if (value.checked) {
      // 追加
      functionPermissions = [...new Set([...permissions, ...value.values])];
    } else {
      // 削除
      functionPermissions = permissions.filter(
        (permission) => !value.values.includes(permission),
      );
    }
    return Permission.valueOf({ ...this, functionPermissions });
  }

  updateAllFunctionPermissions(value) {
    const functionPermissions = value;
    return Permission.valueOf({ ...this, functionPermissions });
  }

  updateClearFunctionPermissions() {
    const functionPermissions = [];
    return Permission.valueOf({ ...this, functionPermissions });
  }

  newPermissions(value) {
    const permissions = this.functionPermissions;
    if (permissions.includes(value)) {
      return permissions.filter((e) => e !== value);
    }
    return [...permissions, value];
  }

  toParam() {
    const { functionPermissions } = this;
    const json = {
      functionPermissions,
    };
    return toSnake(json);
  }
}
