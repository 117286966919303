import dayjs from "@/dayjs";
import { format } from "@/service/date_service";

export class Date {
  constructor(value) {
    this.value = value;
  }

  static empty() {
    return Date.valueOf(null);
  }

  static valueOf(value) {
    return new Date(value ? dayjs(value) : null);
  }

  static today() {
    return new Date(dayjs());
  }

  static todayBeforeMonth(value) {
    return new Date(dayjs().subtract(value, "month"));
  }

  static copyWith({ value }) {
    return Date.valueOf(value);
  }

  toString() {
    const { value } = this;
    if (!value || !value.isValid()) return "";

    return format(value, "YYYY/MM/DD");
  }

  toStringWithTime() {
    const { value } = this;
    if (!value || !value.isValid()) return "";

    return format(value, "YYYY/MM/DD HH:mm");
  }

  toStringMonth() {
    const { value } = this;
    if (!value || !value.isValid()) return "";

    return format(value, "YYYY年 MM月");
  }

  toStringTime() {
    const { value } = this;
    if (!value || !value.isValid()) return "";

    return format(value, "HH:mm");
  }

  toParam() {
    if (!this.isExist) return null;
    return format(this.value, "YYYY-MM-DD");
  }

  updateDate(value) {
    return Date.valueOf(value);
  }

  get isExist() {
    return this.value?.isValid();
  }

  get isToday() {
    return this.value.isToday();
  }

  get beforeToday() {
    return dayjs() < this.value;
  }

  get gtToday() {
    return dayjs().startOf("day") < this.value;
  }

  get ltToday() {
    return dayjs().startOf("day") > this.value;
  }

  get year() {
    const { value } = this;
    if (!value || !value.isValid()) return "";

    return format(value, "YYYY");
  }
  get month() {
    const { value } = this;
    if (!value || !value.isValid()) return "";

    return format(value, "MM");
  }
}
