export const readPermissionCheck = (type, targets) => {
  return targets.some(
    (e) => e.startsWith(`${type}_read`) || e.startsWith(`${type}_save`),
  );
};

export const registerPermissionCheck = (type, targets) => {
  return targets.some((e) => e.startsWith(`${type}_save`));
};

export const updatePermissionCheck = (type, targets) => {
  return targets.includes(`${type}_update`);
};

export const deletePermissionCheck = (type, targets) => {
  return targets.includes(`${type}_delete`);
};

export const permissionCheck = (name, permissions) => {
  return permissions.some((e) => e.startsWith(name));
};

export const billingExecutePermissionCheck = (permissions) => {
  return permissions.some((e) => e === "billing_execution");
};

export const billingReconciliationPermissionCheck = (permissions) => {
  return permissions.some((e) => e === "billing_reconciliation");
};
