import {
  billingExecutionPermissionGuard,
  billingReconciliationPermissionGuard,
  readPermissionGuard,
  registerPermissionGuard,
  updatePermissionGuard,
} from "./guard";

const checkType = (targets, route) =>
  targets.find((t) => route.path.startsWith(t));

export const guardInjection = (routes) => {
  routes.forEach((route) =>
    route.meta ? (route.meta.guard = []) : (route.meta = { guard: [] }),
  );
  readPermissionGuardInjection(routes);
  registerPermissionGuardInjection(routes);
  updatePermissionGuardInjection(routes);
  billingExecutionGuardInjection(routes);
  billingReconciliationGuardInjection(routes);
  paymentGuardInjection(routes);
};

const readPermissionGuardInjection = (routes) => {
  const targets = [
    "/information",
    "/member_management",
    "/account",
    "/billing",
  ];
  routes.forEach((route) => {
    if (checkType(targets, route)) {
      const type = route.path.substr(1, route.path.indexOf("-") - 1);
      route.meta.guard.push(() => readPermissionGuard(type));
    }
  });
};

const registerPermissionGuardInjection = (routes) => {
  const target = "-register";
  routes.forEach((route) => {
    if (route.path.endsWith(target)) {
      // 入金データ作成は別の権限を見る
      if (route.path === "/payment-register") return;

      const index = route.path.indexOf(target);
      const type = route.path.slice(1, index);
      route.meta.guard.push(() => registerPermissionGuard(type));
    }
  });
};

const updatePermissionGuardInjection = (routes) => {
  const targets = ["/information-edit", "/account-edit"];
  routes.forEach((route) => {
    if (checkType(targets, route)) {
      const index = route.path.indexOf("-edit");
      const type = route.path.slice(1, index);
      route.meta.guard.push(() => updatePermissionGuard(type));
    }
  });
};

const billingExecutionGuardInjection = (routes) => {
  const targets = [
    "/billing-bulk-execute",
    "/billing-calculate",
    "/billing-edit",
  ];
  routes.forEach((route) => {
    if (checkType(targets, route)) {
      route.meta.guard.push(() => billingExecutionPermissionGuard());
    }
  });
};

const billingReconciliationGuardInjection = (routes) => {
  const targets = ["/billing-bulk-reconciliation", "/payment-register"];
  routes.forEach((route) => {
    if (checkType(targets, route)) {
      route.meta.guard.push(() => billingReconciliationPermissionGuard());
    }
  });
};

const paymentGuardInjection = (routes) => {
  const target = "/payment-list";
  routes.forEach((route) => {
    if (route.path.startsWith(target)) {
      route.meta.guard.push(() => readPermissionGuard("billing"));
    }
  });
};
