const properties = {
  dataCount: 0,
  dataList: [],
};

export class DataList {
  constructor({ dataList, dataCount }) {
    this.dataList = dataList;
    this.dataCount = dataCount;
  }

  static valueOf(items = properties) {
    return new DataList({ ...properties, ...items });
  }
  static empty() {
    return DataList.valueOf({});
  }
}
