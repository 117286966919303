import { Date } from "@/model/values/date";

const properties = {
  zipCode: "",
  address1: "",
  address2: "",
  address3: "",
  address4: "",
  firstName: "",
  firstNameKana: "",
  lastName: "",
  lastNameKana: "",
  birthday: Date.empty(),
};

export class Representative {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(item = properties) {
    return new Representative({ ...properties, ...item });
  }

  static empty() {
    return Representative.valueOf();
  }
}
