const properties = {
  dataCount: 0,
  listItems: [],
};

export class AccountList {
  static valueOf(items = properties) {
    return new AccountList({ ...properties, ...items });
  }
  static empty() {
    return new AccountList();
  }

  constructor(items = properties) {
    Object.assign(this, items);
  }

  get activeAccountListList() {
    return this.listItems.filter((e) => e.status.isComplete);
  }
}
