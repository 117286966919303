import { status } from "@/const/payment";

export class PaymentStatus {
  constructor(value) {
    this.value = value;
  }

  static valueOf(value = "") {
    return new PaymentStatus(value);
  }
  static empty() {
    return PaymentStatus.valueOf();
  }

  toString() {
    const { value } = this;
    return status[value] || "";
  }
}
