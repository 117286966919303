const dictionary = {
  mailAddress: "メールアドレス",
  mailAddressConfirm: "メールアドレス(確認)",
  loginIdOrMailAddress: "ログインID",
  password: "パスワード",
  passwordConfirm: "パスワード（確認）",
  informationTitle: "タイトル",
  informationMessage: "お知らせ内容",
  postPeriodFrom: "掲載開始",
  postPeriodTo: "掲載終了",
  useStartAt: "利用開始日",
  companyName: "会社名",
  displayCompanyName: "表示名",
  zipCode: "郵便番号",
  address1: "都道府県",
  address2: "市区町村",
  address3: "番地",
  address4: "建物名・号室",
  corporateNumber: "法人番号",
  corporatePhoneNumber: "代表電話番号",
  accountType: "預金種別",
  accountNumber: "口座番号",
  recipientNameKana: "口座名義カナ",
  employeeNumber: "社員番号",
  lastName: "姓",
  firstName: "名",
  lastNameKana: "セイ",
  firstNameKana: "メイ",
  birthday: "誕生日",
  email: "メールアドレス",
  cellphoneNumber: "携帯電話番号",
  ratePlan: "料金プラン",
  bank: "銀行",
  branch: "支店",
  departmentName: "部署名",
  paymentAmount: "入金額",
  paymentDate: "入金日",
  member: "会社名",
  adjustmentAmount: "調整金額",
  adjustmentType: "調整項目",
  freeSearch: "フリーワード",
  memo: "メモ",
  approvalComment: "審査コメント",
};

export const translate = (name) => {
  const field = dictionary[name];
  return field || name;
};
