import { toSnake } from "@/service/convert_service";

const properties = {
  id: 0,
  name: "",
};

export class RatePlan {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(items = properties) {
    return new RatePlan({ ...properties, ...items });
  }
  static empty() {
    return RatePlan.valueOf({});
  }

  toJson() {
    const { id } = this;

    const params = {
      ratePlanId: id,
    };

    return toSnake(params);
  }
}
