const properties = {
  dataCount: 0,
  listItems: [],
};

export class BillingList {
  static valueOf(items = properties) {
    return new BillingList({ ...properties, ...items });
  }
  static empty() {
    return new BillingList();
  }

  constructor(items = properties) {
    Object.assign(this, items);
  }
}
