import { Date } from "@/model/values/date";
import { toSnake } from "@/service/convert_service";
import { AmountOfMoney } from "../values/amount_of_money";
import { PaymentStatus } from "./payment_status";

const properties = {
  paymentId: 0,
  memberId: 0,
  companyName: "",
  displayCompanyName: "",
  paymentDate: Date.empty(),
  paymentMethod: "",
  paymentAmount: AmountOfMoney.empty(),
  allotments: [],
  paymentMemo: "",
  status: PaymentStatus.empty(),
};

export class Payment {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(items = properties) {
    return new Payment({ ...properties, ...items });
  }
  static empty() {
    return Payment.valueOf({});
  }

  toJson() {
    const { memberId, paymentDate, paymentAmount, paymentMemo } = this;
    return toSnake({
      memberId,
      paidAt: paymentDate.toParam(),
      amountOfMoney: paymentAmount.value,
      memo: paymentMemo,
    });
  }

  updateMember(value) {
    return Payment.valueOf({
      ...this,
      memberId: value?.id,
      displayCompanyName: value?.name,
    });
  }
  updatePaymentDate(value) {
    return Payment.valueOf({ ...this, paymentDate: Date.valueOf(value) });
  }
  updatePaymentAmount(value) {
    return Payment.valueOf({
      ...this,
      paymentAmount: AmountOfMoney.valueOf(Number(value)),
    });
  }
  updatePaymentMemo(value) {
    return Payment.valueOf({ ...this, paymentMemo: value });
  }
}
