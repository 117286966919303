import { Member } from "@/model/member/member";
import { MemberStatus } from "@/model/member/member_status";
import { employeeFromJson } from "@/dto/employee";
import { Date } from "@/model/values/date";
import { toCamel } from "@/service/convert_service";
import { AmountOfMoney } from "@/model/values/amount_of_money";
import { ExaminationHistory } from "@/model/member/examination_history";
import { ExaminationMaterial } from "@/model/member/examination_material";
import { UsageLog } from "@/model/member/usage_log";
import { UsageStatus } from "@/model/member/usage_status";
import { accountTypeList } from "@/const/bank";
import { RatePlan } from "@/model/rate_plan/rate_plan";
import { Bank } from "@/model/bank/bank";
import { Branch } from "@/model/bank/branch";
import { Representative } from "@/model/member/representative";

export const memberListItemFromJson = (json) => {
  const {
    memberId,
    companyName,
    displayCompanyName,
    zipCode,
    address1,
    address2,
    address3,
    address4,
    corporateNumber,
    corporatePhoneNumber,
    registeredAt,
    leavedAt,
    useStartAt,
    useStopAt,
    isUseWorkflow,
    numberOfApprovalStep,
    defaultDeadlineDays,
    numberOfEmployees,
    lastUseDate,
    status,
    primaryUser,
    balance,
    bankCode,
    bankName,
    branchCode,
    branchName,
    accountType,
    accountNumber,
    recipientNameKana,
    outstandingMonths,
    paymentCount,
    billDiscountAmount,
    outstandingServiceBill,
    serviceBill,
    totalBill,
    examinationHistories,
    examinationMaterials,
    managementMemo,
    ratePlanId,
    ratePlanName,
    representative,
  } = toCamel(json);

  return Member.valueOf({
    id: memberId,
    companyName,
    displayCompanyName,
    zipCode,
    address1,
    address2,
    address3,
    address4,
    corporateNumber,
    corporatePhoneNumber,
    registeredAt: Date.valueOf(registeredAt),
    leavedAt: Date.valueOf(leavedAt),
    useStartAt: Date.valueOf(useStartAt),
    useStopAt: Date.valueOf(useStopAt),
    isUseWorkflow,
    numberOfApprovalStep,
    defaultDeadlineDays,
    numberOfEmployees,
    lastUseDate: Date.valueOf(lastUseDate),
    status: MemberStatus.valueOf(status),
    primaryUser: employeeFromJson(primaryUser),
    balance: AmountOfMoney.valueOf(balance),
    branchName,
    accountType: accountTypeList.find((type) => type.id === accountType ?? {}),
    accountNumber,
    recipientNameKana,
    outstandingMonths,
    paymentCount,
    billDiscountAmount: AmountOfMoney.valueOf(billDiscountAmount),
    outstandingServiceBill: AmountOfMoney.valueOf(outstandingServiceBill),
    serviceBill: AmountOfMoney.valueOf(serviceBill),
    totalBill: AmountOfMoney.valueOf(totalBill),
    examinationHistories: examinationHistories?.map((history) =>
      examinationHistoryFromJson(toCamel(history)),
    ),
    examinationMaterials: examinationMaterials?.map((material) =>
      examinationMaterialFromJson(toCamel(material)),
    ),
    memo: managementMemo,
    ratePlan: RatePlan.valueOf({ id: ratePlanId, name: ratePlanName }),
    bank: Bank.valueOf({ id: bankCode, name: bankName, code: bankCode }),
    branch: Branch.valueOf({
      id: branchCode,
      name: branchName,
      code: branchCode,
    }),
    representative: representative
      ? representativeFromJson(toCamel(representative))
      : Representative.empty(),
  });
};

const examinationHistoryFromJson = (history) => {
  return ExaminationHistory.valueOf({
    status: MemberStatus.valueOf(history.status),
    comment: history.comment,
    userName: history.userName,
    createdAt: Date.valueOf(history.createdAt),
  });
};

export const examinationMaterialFromJson = (material) => {
  return ExaminationMaterial.valueOf({
    ...material,
    uploadedAt: Date.valueOf(material.uploadedAt),
  });
};

export const usageLogItemFromJson = (json) => {
  const {
    useAt,
    type,
    toMemberId,
    toMemberName,
    amount,
    prefix,
    fee,
    balance,
    status,
    errorReason,
  } = toCamel(json);

  return UsageLog.valueOf({
    useAt: Date.valueOf(useAt),
    type,
    toMemberId,
    toMemberName,
    amount: AmountOfMoney.valueOf(amount),
    prefix,
    fee: AmountOfMoney.valueOf(fee),
    balance: AmountOfMoney.valueOf(balance),
    status: UsageStatus.valueOf(status),
    errorReason,
  });
};

export const usageLogSummaryFromJson = (summaries) => {
  return summaries
    .map((summary) => {
      const split = summary.month.split("-");
      return {
        value: summary.month,
        name: `${split[0]}年 ${split[1]}月`,
      };
    })
    .reverse();
};

const representativeFromJson = (json) => {
  return Representative.valueOf({
    ...json,
    birthday: Date.valueOf(json.birthday),
  });
};
