import router from "@/router";

export const moveToRoot = () => {
  router.push({ path: "/" }).catch(() => {});
};

export const goBack = () => {
  router.back();
};

export const reload = () => {
  router.go();
};

export const moveLoginOnReplace = () => {
  router.replace({ path: "/login" });
};

export const moveHome = () => {
  router.push({ path: "/home" });
};

export const moveHomeOnReplace = () => router.replace({ path: "/home" });

export const moveLoginPasswordSendMail = () => {
  router.push({ path: "/login-password-send-mail" });
};

export const moveLoginPasswordSendMailConfirm = () => {
  router.push({ path: "/login-password-send-mail-confirm" });
};

export const moveToHomeOnReplace = () => {
  router.replace({ path: "/home" });
};

export const moveLoginRegisterComplete = () =>
  router.push({ path: "/login-register-complete" });

export const moveInformationList = () => {
  router.push({ path: "/information-list" });
};

export const moveInformationRegister = () => {
  router.push({ path: "/information-register" });
};

export const moveInformationEdit = (id) => {
  router.push({ path: `/information-edit/${id}` });
};

export const moveMemberList = () => {
  router.push({ path: `/member_management-list` });
};

export const moveMemberDetail = (id) => {
  router.push({ path: `/member_management-detail/${id}` });
};

export const moveAccountInvitation = () => {
  router.push({ path: `/account-register` });
};

export const moveAccountList = () => {
  router.push({ path: `/account-list` });
};

export const moveAccountEdit = (id) => {
  router.push({ path: `/account-edit/${id}` });
};

export const moveBillingList = () => {
  router.push({ path: `/billing-list` });
};

export const moveBillingDetail = (id) => {
  router.push({ path: `/billing-detail/${id}` });
};

export const moveBillingEdit = (id) => {
  router.push({ path: `/billing-edit/${id}` });
};

export const moveBillingBulkReconciliation = () => {
  router.push({ path: "/billing-bulk-reconciliation" });
};

export const moveBillingBulkExecute = () => {
  router.push({ path: "/billing-bulk-execute" });
};

export const moveBillingCalculate = () => {
  router.push({ path: "/billing-calculate" });
};

export const moveToNotFound = () => {
  router.push({ path: "/not-found" });
};

export const moveUserInfo = () => {
  router.push({ path: "/user-info" });
};

export const moveUserInfoEdit = () => {
  router.push({ path: "/user-info-edit" });
};

export const movePaymentList = () => {
  router.push({ path: "/payment-list" });
};

export const movePaymentRegister = () => {
  router.push({ path: "/payment-register" });
};

export const movePaymentDetail = (id) => {
  router.push({ path: `/payment-detail/${id}` });
};
