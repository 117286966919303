const properties = {
  mftUserId: 0,
  email: "",
  jwtToken: "",
};

export class RegisterAuth {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(item = properties) {
    const items = { ...properties, ...item };
    return new RegisterAuth(items);
  }

  static empty() {
    return RegisterAuth.valueOf();
  }
  get jwt() {
    return this.jwtToken;
  }
}
