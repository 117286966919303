import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/auth";
import form from "@/store/form";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    form,
  },
  plugins: [
    createPersistedState({
      key: "rakutasu",
      paths: ["form.numberOfData"],
    }),
  ],
});
