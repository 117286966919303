import { PageNation } from "@/model/form/page_nation";
import { toSnake } from "@/service/convert_service";
import { DateRange } from "../values/date_range";

const properties = {
  freeword: "",
  paymentDate: DateRange.empty(),
  pageNation: PageNation.firstPage(),
  sortDirection: "desc",
  sortColumn: "payment_id",
};

export class PaymentListSearchForm {
  constructor({
    freeword,
    paymentDate,
    pageNation,
    sortColumn,
    sortDirection,
  }) {
    this.freeword = freeword;
    this.paymentDate = paymentDate;
    this.pageNation = pageNation;
    this.sortColumn = sortColumn;
    this.sortDirection = sortDirection;
  }

  static valueOf(items = {}) {
    return new PaymentListSearchForm({ ...properties, ...items });
  }

  static empty() {
    return new PaymentListSearchForm(properties);
  }

  clear() {
    const { pageNation } = this;
    return PaymentListSearchForm.valueOf({ ...properties, ...{ pageNation } });
  }

  updateFreeword(value) {
    return PaymentListSearchForm.valueOf({ ...this, ...{ freeword: value } });
  }

  updatePaymentDate(value) {
    const paymentDate = this.paymentDate.updateDateRange(value);
    return PaymentListSearchForm.valueOf({ ...this, ...{ paymentDate } });
  }

  updateNumberOfData(value) {
    const pageNation = this.pageNation.updateNumberOfData(value);
    return new PaymentListSearchForm({ ...this, ...{ pageNation } });
  }

  updatePage(value) {
    const pageNation = this.pageNation.updatePage(value);
    return new PaymentListSearchForm({ ...this, ...{ pageNation } });
  }
  updateDataCount(value) {
    const pageNation = this.pageNation.updateDataCount(value);
    return new PaymentListSearchForm({ ...this, ...{ pageNation } });
  }

  updateSortDirection() {
    const value = this.sortDirection === "desc" ? "asc" : "desc";
    return PaymentListSearchForm.valueOf({
      ...this,
      ...{ sortDirection: value },
    });
  }

  updateSortColumn(value) {
    return PaymentListSearchForm.valueOf({ ...this, ...{ sortColumn: value } });
  }

  toJson(isCsv) {
    const { freeword, paymentDate, pageNation, sortColumn, sortDirection } =
      this;
    const params = isCsv ? {} : pageNation.toJson();
    if (freeword) params.freeword = freeword;
    if (paymentDate.isValid) {
      params.payment_date_from = paymentDate.start.toParam();
      params.payment_date_to = paymentDate.end.toParam();
    }
    params.sort_direction = sortDirection;
    params.sort_column = sortColumn;

    return toSnake(params);
  }
}
