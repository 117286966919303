import { status } from "@/const/billing_status";

export class BillingStatus {
  constructor(value) {
    this.value = value;
  }

  static valueOf(value = "") {
    return new BillingStatus(value);
  }
  static empty() {
    return BillingStatus.valueOf();
  }

  toString() {
    const { value } = this;
    return status[value]?.name || "";
  }

  get isTextStyle() {
    const { value } = this;
    return status[value]?.text;
  }

  get isUnbilling() {
    const { value } = this;
    return value === "unbilling";
  }

  get isUnpaid() {
    const { value } = this;
    return value === "unpaid";
  }
}
