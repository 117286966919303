export const paymentMethod = {
  bank_transfer: "銀行振込",
  manual_bank_transfer: "銀行振込（手動）",
  other: "楽たす外入金",
};

export const status = {
  paid: "入金済",
  partially_paid: "一部入金",
  advance_paid: "前入金",
};
