import { MemberStatus } from "./member_status";
import { Date } from "@/model/values/date";

const properties = {
  status: MemberStatus.empty(),
  comment: "",
  userName: "",
  createdAt: Date.empty(),
};

export class ExaminationHistory {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(items = properties) {
    return new ExaminationHistory({ ...properties, ...items });
  }
  static empty() {
    return ExaminationHistory.valueOf({});
  }
}
