import Repository from "@/infrastructure/api";
import { RegisterAuth } from "@/model/auth_info/register_auth";
import { toCamel } from "@/service/convert_service";

const resource = "/invitation";
export class InvitationRepository {
  invitationAccount = async (payload) => {
    await Repository.post(`${resource}/mft_user`, payload);
  };

  authenticateToken = async (payload) => {
    const { data } = await Repository.post(
      `${resource}/mft_user/auth`,
      payload,
    );
    const params = toCamel(data);
    return RegisterAuth.valueOf(params);
  };
}
