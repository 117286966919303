import { AccountListSearchForm } from "@/model/account/account_list_search_form";
import { InformationListSearchForm } from "@/model/information/information_list_search_form";
import { BillingListSearchForm } from "@/model/billing/billing_list_search_form";
import { MemberListSearchForm } from "@/model/member/member_list_search_form";
import { PaymentListSearchForm } from "@/model/payment/payment_list_search_form";

const initState = {
  accountListSearchForm: AccountListSearchForm.empty(),
  informationListSearchForm: InformationListSearchForm.empty(),
  billingListSearchForm: BillingListSearchForm.empty(),
  billingBulkReconciliationSearchForm: BillingListSearchForm.empty(),
  billingBulkExecuteSearchForm: BillingListSearchForm.empty(),
  memberListSearchForm: MemberListSearchForm.empty(),
  paymentListSearchForm: PaymentListSearchForm.empty(),
  numberOfData: {
    account: 10,
    information: 10,
    billing: 10,
    billingBulkReconciliation: 10,
    billingBulkExecute: 10,
    member: 10,
    payment: 10,
  },
};

export default {
  namespaced: true,
  state: {
    ...initState,
  },
  mutations: {
    updateAccountListSearchForm: (state, { form }) => {
      state.accountListSearchForm = form;
      state.numberOfData.account = form.pageNation.numberOfData;
    },
    updateInformationListSearchForm: (state, { form }) => {
      state.informationListSearchForm = form;
      state.numberOfData.information = form.pageNation.numberOfData;
    },
    updateBillingListSearchForm: (state, { form }) => {
      state.billingListSearchForm = form;
      state.numberOfData.billing = form.pageNation.numberOfData;
    },
    updateBillingBulkReconciliationSearchForm: (state, { form }) => {
      state.billingBulkReconciliationSearchForm = form;
      state.numberOfData.billingBulkReconciliation = form.numberOfData;
    },
    updateBillingBulkExecuteSearchForm: (state, { form }) => {
      state.billingBulkExecuteSearchForm = form;
      state.numberOfData.billingBulkExecute = form.numberOfData;
    },
    updateMemberListSearchForm: (state, { form }) => {
      state.memberListSearchForm = form;
      state.numberOfData.member = form.pageNation.numberOfData;
    },
    updatePaymentListSearchForm: (state, { form }) => {
      state.paymentListSearchForm = form;
      state.numberOfData.payment = form.pageNation.numberOfData;
    },
    clear: (state) => (state = { ...state, ...initState }),
  },
  actions: {},
  getters: {
    accountListSearchForm: (state) =>
      state.accountListSearchForm.updateNumberOfData(
        state.numberOfData.account,
      ),
    informationListSearchForm: (state) =>
      state.informationListSearchForm.updateNumberOfData(
        state.numberOfData.information,
      ),
    billingListSearchForm: (state) =>
      state.billingListSearchForm.updateNumberOfData(
        state.numberOfData.billing,
      ),
    billingBulkReconciliationSearchForm: (state) =>
      state.billingBulkReconciliationSearchForm.updateNumberOfData(
        state.numberOfData.billingBulkReconciliation,
      ),
    billingBulkExecuteSearchForm: (state) =>
      state.billingBulkExecuteSearchForm.updateNumberOfData(
        state.numberOfData.billingBulkExecute,
      ),
    memberListSearchForm: (state) =>
      state.memberListSearchForm.updateNumberOfData(state.numberOfData.member),
    paymentListSearchForm: (state) =>
      state.paymentListSearchForm.updateNumberOfData(
        state.numberOfData.payment,
      ),
  },
};
