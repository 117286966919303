import { paymentFromJson } from "@/dto/payment";
import Repository from "@/infrastructure/api";
import { DataList } from "@/model/values/data_list";

const resources = "/management/bill_payments";
export class PaymentRepository {
  fetchPaymentList = async (params) => {
    const { data } = await Repository.get(`${resources}`, { params });
    const dataCount = data["data_count"];
    const currentPaymentList = data["current_payment_list"];
    const dataList = currentPaymentList.map((json) => paymentFromJson(json));
    return DataList.valueOf({ dataCount, dataList });
  };
  fetchPayment = async (id) => {
    const { data } = await Repository.get(`${resources}/${id}`);
    return paymentFromJson(data);
  };
  register = async (payload) => await Repository.post(`${resources}`, payload);
  delete = async (id) => await Repository.delete(`${resources}/${id}`);
  update = async (payload, id) =>
    await Repository.put(`${resources}/${id}`, payload);
  fetchPaymentCsvData = async (params) => {
    const { data } = await Repository.post(
      `${resources}/export_service_bill_payments_csv?${params}`,
    );
    return data;
  };
}
