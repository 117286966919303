import { Account } from "../account/account";

const properties = {
  account: Account.empty(),
  authenticated: false,
};

export class AuthInfo {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(item = properties) {
    const items = { ...properties, ...item };
    return new AuthInfo(items);
  }

  static empty() {
    return AuthInfo.valueOf();
  }

  get usernameShort() {
    const { lastName, firstName } = this.account;
    return `${lastName} ${firstName}`;
  }
}
