import { Date } from "@/model/values/date";
import { AmountOfMoney } from "../values/amount_of_money";

const properties = {
  targetMonth: Date.empty(),
  finalBillingAmount: AmountOfMoney.empty(),
  unpaidAmount: AmountOfMoney.empty(),
  allottedAmount: AmountOfMoney.empty(),
  remainingAmount: AmountOfMoney.empty(),
  status: "",
};

export class Allotment {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(items = properties) {
    return new Allotment({ ...properties, ...items });
  }
  static empty() {
    return Allotment.valueOf({});
  }
}
