import { toCamel } from "@/service/convert_service";
import { AmountOfMoney } from "@/model/values/amount_of_money";
import { Date } from "@/model/values/date";
import { Payment } from "@/model/payment/payment";
import { Allotment } from "@/model/payment/allotment";
import { PaymentStatus } from "@/model/payment/payment_status";

export const paymentFromJson = (json) => {
  const {
    paymentId,
    memberId,
    companyName,
    displayCompanyName,
    paymentMethod,
    paymentDate,
    paymentAmount,
    status,
    allotments,
    paymentMemo,
  } = toCamel(json);

  return Payment.valueOf({
    paymentId,
    memberId,
    companyName,
    displayCompanyName,
    paymentDate: Date.valueOf(paymentDate),
    paymentMethod,
    paymentAmount: AmountOfMoney.valueOf(paymentAmount),
    status: PaymentStatus.valueOf(allotments.length ? status : "advance_paid"),
    allotments: allotments.map((v) => allotmentFromJson(v)),
    paymentMemo,
  });
};

const allotmentFromJson = (json) => {
  const {
    targetMonth,
    finalBillingAmount,
    unpaidAmount,
    allottedAmount,
    remainingAmount,
    status,
  } = toCamel(json);

  return Allotment.valueOf({
    targetMonth: Date.valueOf(targetMonth),
    finalBillingAmount: AmountOfMoney.valueOf(finalBillingAmount),
    unpaidAmount: AmountOfMoney.valueOf(unpaidAmount),
    allottedAmount: AmountOfMoney.valueOf(allottedAmount),
    remainingAmount: AmountOfMoney.valueOf(remainingAmount),
    status: PaymentStatus.valueOf(status),
  });
};
