export class PermissionError extends Error {
  constructor(...params) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, PermissionError);
    }

    this.name = this.constructor.name;
  }

  toString() {
    return "権限がありません\n機能権限を確認してください";
  }
}
