import { AuthInfo } from "@/model/auth_info/auth_info";

export default {
  namespaced: true,
  state: {
    jwt: "",
    authInfo: AuthInfo.empty(),
  },
  mutations: {
    updateJwt: (state, { jwt }) => {
      state.jwt = jwt;
    },
    updateAuthInfo: (state, payload) =>
      (state.authInfo = AuthInfo.valueOf({ ...payload, authenticated: true })),
    clearJwt: (state) => (state.jwt = ""),
    clearAuthInfo: (state) => (state.authInfo = AuthInfo.empty()),
  },
  actions: {},
  getters: {
    jwt: (state) => state.jwt,
    authInfo: (state) => state.authInfo,
    authenticated: (state) => state.authInfo.authenticated,
  },
};
