import { clearJwt } from "./auth_service";
import { moveToNotFound } from "./router_service";

// TODO: message以外も返すようになったので名前変更
export const errorToMsg = (err) => {
  const { response } = err;
  if (!response) {
    return { message: err };
  }
  if (response.status == 404) {
    return { action: () => moveToNotFound() };
  }
  if (response.status == 401) {
    clearJwt();
    return {
      message: parseErrorResponse(response),
      action: () => (location.href = "/login"),
    };
  }

  return { message: parseErrorResponse(response) };
};

// TODO: 名前変更
export const errorToMsgNoAction = (err) => {
  const { response } = err;
  if (!response) {
    return err;
  }
  return parseErrorResponse(response);
};

export const parseErrorResponse = (response) => {
  if (!response.data) {
    return response.message;
  }
  const { errors } = response.data;
  if (!errors) return response.data;
  if (!Array.isArray(errors)) return errors;
  if (!errors.length) return errors;
  return errors[0].message;
};

const toUnderscoreCase = (str) =>
  str
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase();
export const toSnake = (obj) => {
  const result = {};
  Object.keys(obj).forEach((key) => {
    result[toUnderscoreCase(key)] = obj[key];
  });
  return result;
};

const toCamelCase = (str) => {
  const fn = (word, index) => {
    if (index === 0) return word.toLowerCase();
    const first = word.charAt(0).toUpperCase();
    const other = word.slice(1).toLowerCase();
    return first + other;
  };
  const words = str.split("_").map((word, index) => fn(word, index));
  return words.join("");
};

export const toCamel = (obj) => {
  const result = {};
  Object.keys(obj).forEach((key) => {
    result[toCamelCase(key)] = obj[key];
  });
  return result;
};

export const fullWidthCharToHalfWidthChar = (str) => {
  return str
    .replace(/[Ａ-Ｚａ-ｚ０-９]/g, (char) =>
      String.fromCharCode(char.charCodeAt(0) - 65248),
    )
    .replace(/[ー−‐]/g, "-");
};
