import { Employee } from "@/model/employee/employee";
import { Date } from "@/model/values/date";
import { toCamel } from "@/service/convert_service";

export const employeeFromJson = (json) => {
  const {
    employeeId,
    employeeNumber,
    lastName,
    firstName,
    lastNameKana,
    firstNameKana,
    birthday,
    email,
    cellphoneNumber,
  } = toCamel(json);

  return Employee.valueOf({
    id: employeeId,
    employeeNumber,
    lastName,
    lastNameKana,
    firstName,
    firstNameKana,
    birthday: Date.valueOf(birthday),
    email,
    cellphoneNumber,
  });
};
