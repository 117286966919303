export const status = {
  unbilling: { name: "未請求" },
  billing: { name: "請求済", text: true },
  unpaid: {
    name: "未消込",
    text: true,
  },
  paid: {
    name: "入金済",
  },
  partially_paid: {
    name: "一部入金",
  },
  overdue: {
    name: "滞留",
    text: true,
  },
};
