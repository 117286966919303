import { clearJwt } from "@/service/auth_service";
import store from "@/store";

export const noAuthFlow = (to, next) => {
  authClear();
  if (to.meta && to.meta.authRequired) next({ path: "/login", replace: true });
  else next();
};

const authClear = () => {
  clearJwt();
  store.commit("auth/clearJwt");
  store.commit("auth/clearAuthInfo");
};
