import { AmountOfMoney } from "../values/amount_of_money";
import { BillingStatus } from "./billing_status";
import { Date } from "@/model/values/date";

const properties = {
  billId: 0,
  memberId: 0,
  companyName: "",
  displayCompanyName: "",
  targetMonth: Date.empty(),
  billingDate: Date.empty(),
  paymentDate: Date.empty(),
  paymentDeadline: Date.empty(),
  numberOfTransfers: 0,
  billingAmount: AmountOfMoney.empty(),
  discountAmount: AmountOfMoney.empty(),
  overdueBillingAmount: AmountOfMoney.empty(),
  overdueDiscountAmount: AmountOfMoney.empty(),
  overdueMonths: 0,
  finalBillingAmount: AmountOfMoney.empty(),
  paymentAmount: AmountOfMoney.empty(),
  status: BillingStatus.empty(),
  corporateNumber: "",
  corporatePhoneNumber: "",
  numberOfEmployees: 0,
  zipCode: "",
  address1: "",
  address2: "",
  address3: "",
  address4: "",
  bankName: "",
  branchName: "",
  accountType: "",
  accountNumber: "",
  accountNameKana: "",
  adjustments: [],
  campaignName: "",
};

export class Billing {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(items = properties) {
    return new Billing({ ...properties, ...items });
  }
  static empty() {
    return Billing.valueOf({});
  }
}
