import { baseURL } from "@/config";
import store from "@/store";
import axios from "axios";

const csrfToken =
  process.env.NODE_ENV === "test"
    ? ""
    : document.querySelector('meta[name="csrf-token"]').getAttribute("content");

const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "X-CSRF-Token": csrfToken,
  },
  timeout: 60 * 1000,
});

api.interceptors.request.use(
  (request) => {
    request.headers = newHeader(request);
    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.request.use((response) => {
  const isTimeout = response.code === "ECONNABORTED";
  if (!isTimeout) {
    return response;
  }
  return response;
});

const newHeader = (request) => {
  const { jwt } = store.state.auth;
  if (!jwt || request.url.match(/\/banks/)) {
    return request.headers;
  }
  return { ...request.headers, ...{ Authorization: `Bearer ${jwt}` } };
};
export default api;
