import { Date } from "@/model/values/date";
import { toSnake } from "@/service/convert_service";

const properties = {
  id: 0,
  // title: "",
  message: "",
  isImportant: false,
  type: "",
  postPeriodFrom: Date.empty(),
  postPeriodTo: Date.empty(),
};

export class Information {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(items = properties) {
    return new Information({ ...properties, ...items });
  }
  static empty() {
    return Information.valueOf({});
  }

  toJson() {
    const {
      // title,
      message,
      isImportant,
      postPeriodFrom,
      postPeriodTo,
    } = this;

    const params = {
      message,
      isImportant,
      postPeriodFrom: postPeriodFrom.toParam(),
      postPeriodTo: postPeriodTo.toParam(),
      type: "announce",
      destination: "",
    };

    return toSnake(params);
  }

  // updateTitle(value) {
  //   const properties = { ...this, ...{ title: value } }
  //   return Information.valueOf(properties)
  // }
  updateMessage(value) {
    const properties = { ...this, ...{ message: value } };
    return Information.valueOf(properties);
  }
  updatePostPeriodFrom(value) {
    const properties = { ...this, ...{ postPeriodFrom: value } };
    return Information.valueOf(properties);
  }
  updatePostPeriodTo(value) {
    const properties = { ...this, ...{ postPeriodTo: value } };
    return Information.valueOf(properties);
  }
  updateIsImportant() {
    const properties = { ...this, ...{ isImportant: !this.isImportant } };
    return Information.valueOf(properties);
  }
}
