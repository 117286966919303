// isPending: バッジの色を紫にする
// isDisabled: 文字色をグレーにする

export const status = {
  new_registration: { name: "新規申込", isPending: true, isDisabled: false },
  examination: { name: "審査中", isPending: true, isDisabled: false },
  imcomplete_documents: {
    name: "不備再提出",
    isPending: true,
    isDisabled: false,
  },
  failure_of_examination: {
    name: "審査落ち",
    isPending: false,
    isDisabled: true,
  },
  waiting_to_start_using: {
    name: "利用開始待ち",
    isPending: false,
    isDisabled: false,
  },
  gmoa_before_examination: {
    name: "GMO申込前",
    isPending: true,
    isDisabled: false,
  },
  gmoa_examination_in_progress: {
    name: "GMO審査中",
    isPending: true,
    isDisabled: false,
  },
  gmoa_activation_pending: {
    name: "GMO未連携",
    isPending: true,
    isDisabled: false,
  },
  gmoa_activated: { name: "GMO利用中", isPending: false, isDisabled: false },
  gmoa_application_declined: {
    name: "GMO審査謝絶",
    isPending: false,
    isDisabled: true,
  },
  gmoa_application_canceled: {
    name: "GMO審査取消",
    isPending: false,
    isDisabled: false,
  },
  dormant: { name: "停止中", isPending: false, isDisabled: false },
  unsubsribe: { name: "解約", isPending: false, isDisabled: false },
};
