import { PageNation } from "@/model/form/page_nation";
import { toSnake } from "@/service/convert_service";
import { DateRange } from "../values/date_range";

const properties = {
  freeword: "",
  status: [],
  pageNation: PageNation.firstPage(),
  billingDate: DateRange.empty(),
  paymentDate: DateRange.empty(),
  sortDirection: "asc",
  sortColumn: "member_id",
};

export class BillingListSearchForm {
  constructor({
    freeword,
    status,
    billingDate,
    paymentDate,
    pageNation,
    sortColumn,
    sortDirection,
  }) {
    this.freeword = freeword;
    this.status = status;
    this.billingDate = billingDate;
    this.paymentDate = paymentDate;
    this.pageNation = pageNation;
    this.sortColumn = sortColumn;
    this.sortDirection = sortDirection;
  }

  static valueOf(items = properties) {
    return new BillingListSearchForm({ ...properties, ...items });
  }

  static empty() {
    return new BillingListSearchForm(properties);
  }

  clear() {
    const { pageNation } = this;
    return BillingListSearchForm.valueOf({ ...properties, ...{ pageNation } });
  }
  updateFreeword(value) {
    return new BillingListSearchForm({ ...this, ...{ freeword: value } });
  }
  updateStatus(value) {
    return BillingListSearchForm.valueOf({ ...this, ...{ status: value } });
  }
  updateBillingDate(value) {
    const billingDate = this.billingDate.updateDateRange(value);
    return BillingListSearchForm.valueOf({ ...this, ...{ billingDate } });
  }
  updatePaymentDate(value) {
    const paymentDate = this.paymentDate.updateDateRange(value);
    return BillingListSearchForm.valueOf({ ...this, ...{ paymentDate } });
  }

  updateNumberOfData(value) {
    const pageNation = this.pageNation.updateNumberOfData(value);
    return new BillingListSearchForm({ ...this, ...{ pageNation } });
  }

  updatePage(value) {
    const pageNation = this.pageNation.updatePage(value);
    return new BillingListSearchForm({ ...this, ...{ pageNation } });
  }
  updateDataCount(value) {
    const pageNation = this.pageNation.updateDataCount(value);
    return new BillingListSearchForm({ ...this, ...{ pageNation } });
  }

  updateSortDirection() {
    const value = this.sortDirection === "desc" ? "asc" : "desc";
    return BillingListSearchForm.valueOf({
      ...this,
      ...{ sortDirection: value },
    });
  }

  updateSortColumn(value) {
    return BillingListSearchForm.valueOf({
      ...this,
      ...{ sortColumn: value },
    });
  }

  toJson(isCsv) {
    const {
      freeword,
      status,
      billingDate,
      paymentDate,
      pageNation,
      sortColumn,
      sortDirection,
    } = this;
    const params = isCsv ? {} : pageNation.toJson();
    params.sort_direction = sortDirection;
    params.sort_column = sortColumn;

    if (freeword) params.freeword = freeword;
    if (status.length) params.status = status;
    if (billingDate.isValid) {
      params.billing_date_from = billingDate.start.toParam();
      params.billing_date_to = billingDate.end.toParam();
    }
    if (paymentDate.isValid) {
      params.payment_date_from = paymentDate.start.toParam();
      params.payment_date_to = paymentDate.end.toParam();
    }

    return toSnake(params);
  }
}
