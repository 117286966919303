import Repository from "@/infrastructure/api";
import {
  memberListItemFromJson,
  usageLogItemFromJson,
  usageLogSummaryFromJson,
} from "@/dto/member";
import { DataList } from "@/model/values/data_list";

const resource = "management/members";
const detailResource = "management/member";
export class MemberRepository {
  fetchMemberList = async (params) => {
    const { data } = await Repository.get(resource, { params });
    const dataCount = data["data_count"];
    const members = data["current_file_list"].map((json) =>
      memberListItemFromJson(json),
    );
    return DataList.valueOf({
      dataCount,
      dataList: members,
    });
  };

  fetchMember = async (id) => {
    const { data } = await Repository.get(`${detailResource}/${id}`);
    return memberListItemFromJson(data);
  };

  register = async (payload) => await Repository.post(resource, payload);

  update = async (id, payload) =>
    await Repository.put(`${detailResource}/${id}`, payload);

  delete = async (id) => await Repository.delete(`${resource}/${id}`);

  updateStatus = async (id, payload) => {
    await Repository.put(`member/${id}/status`, payload);
    // const { data } = await Repository.put(`member/${id}/status`, payload)
    // return memberListItemFromJson(data);
  };

  fetchUsageLogs = async (id, params) => {
    const { data } = await Repository.get(
      `${detailResource}/${id}/usage_logs`,
      { params },
    );

    const dataCount = data["data_count"];
    const usageLogs = data["current_usage_log_list"].map((json) =>
      usageLogItemFromJson(json),
    );
    return DataList.valueOf({
      dataCount,
      dataList: usageLogs,
    });
  };

  fetchUsageLogsSummary = async (id) => {
    const { data } = await Repository.get(
      `${detailResource}/${id}/usage_logs/summary`,
    );

    return usageLogSummaryFromJson(data["summaries"]);
  };

  sendReApplicationMail = async (id) => {
    await Repository.post(`${detailResource}/${id}/reapplication-mail`);
  };

  fetchMemberCsvData = async (params) => {
    const { data } = await Repository.post(
      `${resource}/export_members_csv?${params}`,
    );
    return data;
  };

  fetchEmployeeCsvData = async (params) => {
    const { data } = await Repository.post(
      `${resource}/export_employees_csv?${params}`,
    );
    return data;
  };

  fetchServiceBillCsvData = async (params) => {
    const { data } = await Repository.post(
      `${resource}/export_service_bills_csv?${params}`,
    );
    return data;
  };
}
