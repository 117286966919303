import { usage_status } from "@/const/usage_log";

export class UsageStatus {
  constructor(value) {
    this.value = value;
  }

  static valueOf(value = "") {
    return new UsageStatus(value);
  }
  static empty() {
    return UsageStatus.valueOf();
  }

  toString() {
    const { value } = this;
    return usage_status[value]?.name || "";
  }

  get isError() {
    const { value } = this;
    return usage_status[value]?.isError;
  }
}
