import Repository from "@/infrastructure/api";
import { informationListItemFromJson } from "@/dto/information";
import { InformationList } from "@/model/information/information_list";

const resource = "management/informations";
export class InformationRepository {
  fetchInformationList = async (params) => {
    const { data } = await Repository.get(resource, { params });
    const dataCount = data["data_count"];
    const announce = data["data"].map((json) =>
      informationListItemFromJson(json),
    );
    return InformationList.valueOf({
      dataCount,
      announce,
    });
  };

  fetchInformation = async (id) => {
    const { data } = await Repository.get(`${resource}/${id}`);
    return informationListItemFromJson(data);
  };

  register = async (payload) => await Repository.post(resource, payload);

  update = async (id, payload) =>
    await Repository.put(`${resource}/${id}`, payload);

  delete = async (id) => await Repository.delete(`${resource}/${id}`);
}
