import { status } from "@/const/member_status";

export class MemberStatus {
  constructor(value) {
    this.value = value;
  }

  static valueOf(value = "") {
    return new MemberStatus(value);
  }
  static empty() {
    return MemberStatus.valueOf();
  }

  toString() {
    const { value } = this;
    return status[value]?.name || "";
  }

  get isPending() {
    const { value } = this;
    return status[value]?.isPending;
  }
  get isDisabled() {
    const { value } = this;
    return status[value]?.isDisabled;
  }
}
