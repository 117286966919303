import { Billing } from "@/model/billing/billing";
import { toCamel } from "@/service/convert_service";
import { AmountOfMoney } from "@/model/values/amount_of_money";
import { BillingStatus } from "@/model/billing/billing_status";
import { Date } from "@/model/values/date";
import { BillingAdjustment } from "@/model/billing/billing_adjustment";

export const billFromJson = (json) => {
  const {
    billId,
    memberId,
    companyName,
    displayCompanyName,
    targetMonth,
    billingDate,
    paymentDate,
    paymentDeadline,
    numberOfTransfers,
    billingAmount,
    discountAmount,
    overdueBillingAmount,
    overdueDiscountAmount,
    overdueMonths,
    finalBillingAmount,
    paymentAmount,
    status,
    corporateNumber,
    corporatePhoneNumber,
    numberOfEmployees,
    zipCode,
    address1,
    address2,
    address3,
    address4,
    bankName,
    branchName,
    accountType,
    accountNumber,
    accountNameKana,
    adjustments,
    campaignName,
  } = toCamel(json);

  return Billing.valueOf({
    billId,
    memberId,
    companyName,
    displayCompanyName,
    targetMonth: Date.valueOf(targetMonth),
    billingDate: Date.valueOf(billingDate),
    paymentDate: Date.valueOf(paymentDate),
    paymentDeadline: Date.valueOf(paymentDeadline),
    numberOfTransfers,
    billingAmount: AmountOfMoney.valueOf(billingAmount),
    discountAmount: AmountOfMoney.valueOf(discountAmount),
    overdueBillingAmount: AmountOfMoney.valueOf(overdueBillingAmount),
    overdueDiscountAmount: AmountOfMoney.valueOf(overdueDiscountAmount),
    overdueMonths,
    finalBillingAmount: AmountOfMoney.valueOf(finalBillingAmount),
    paymentAmount: AmountOfMoney.valueOf(paymentAmount),
    status: BillingStatus.valueOf(status),
    corporateNumber,
    corporatePhoneNumber,
    numberOfEmployees,
    zipCode,
    address1,
    address2,
    address3,
    address4,
    bankName,
    branchName,
    accountType,
    accountNumber,
    accountNameKana,
    adjustments: adjustments?.map((json) => adjustmentFromJson(json)),
    campaignName,
  });
};

const adjustmentFromJson = (json) => {
  const { type, adjustmentAmount, adjustmentComment, adjustedAt } =
    toCamel(json);

  return BillingAdjustment.valueOf({
    type,
    adjustedAt: Date.valueOf(adjustedAt),
    adjustmentAmount: AmountOfMoney.valueOf(adjustmentAmount),
    adjustmentComment,
  });
};
