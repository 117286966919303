import { mftUserFromJson } from "@/dto/mft_user";
import Repository from "@/infrastructure/api";
import { AccountList } from "@/model/account/account_list";

const resources = "/management/mft_users";
export class MftUserRepository {
  fetchAccountList = async (params) => {
    const { data } = await Repository.get(`${resources}`, { params });

    const dataCount = data["data_count"];
    const currentAccountList = data["current_mft_user_list"];
    const listItems = currentAccountList.map((json) => mftUserFromJson(json));
    return AccountList.valueOf({ dataCount, listItems });
  };
  resetEmail = async (payload) => {
    await Repository.post(`${resources}/email-reset-mail`, payload);
  };
  resetPassword = async (payload) => {
    await Repository.post(`${resources}/password-reset-mail`, payload);
  };
  updateEmail = async (payload) => {
    await Repository.put(`${resources}/email`, payload);
  };
  updatePassword = async (payload) => {
    await Repository.put(`${resources}/password`, payload);
  };
  fetchAccount = async (id) => {
    const { data } = await Repository.get(`${resources}/${id}`);
    return mftUserFromJson(data);
  };
  updateAccount = async (id, payload) => {
    await Repository.put(`${resources}/${id}`, payload);
  };
  deleteAccount = async (id) => {
    await Repository.delete(`${resources}/${id}`);
  };
  registerAccount = async (payload, headers) => {
    await Repository.post(`${resources}/register`, payload, { headers });
  };
}
