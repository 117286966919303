import { PageNation } from "@/model/form/page_nation";
import { toSnake } from "@/service/convert_service";

const properties = {
  pageNation: PageNation.firstPage(),
};

export class InformationListSearchForm {
  constructor({ pageNation }) {
    this.pageNation = pageNation;
  }

  static valueOf({ pageNation }) {
    return new InformationListSearchForm({ pageNation });
  }

  static empty() {
    return new InformationListSearchForm(properties);
  }

  updateNumberOfData(value) {
    const pageNation = this.pageNation.updateNumberOfData(value);
    return new InformationListSearchForm({ ...this, ...{ pageNation } });
  }

  updatePage(value) {
    const pageNation = this.pageNation.updatePage(value);
    return new InformationListSearchForm({ ...this, ...{ pageNation } });
  }
  updateDataCount(value) {
    const pageNation = this.pageNation.updateDataCount(value);
    return new InformationListSearchForm({ ...this, ...{ pageNation } });
  }

  toJson() {
    const { pageNation } = this;
    const params = pageNation.toJson();

    return toSnake(params);
  }
}
