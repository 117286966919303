import { AuthInfo } from "@/model/auth_info/auth_info";
import { clearJwt, hasAuthInfo } from "@/service/auth_service";

export class AuthInfoBehavior {
  constructor({ repository, authInfo }) {
    this.repository = repository;
    this.authInfo = authInfo;
  }

  static create({ repository }) {
    return new AuthInfoBehavior({ repository, authInfo: AuthInfo.empty() });
  }

  tryLogout() {
    clearJwt();
  }

  async initialize(authInfoOnStore) {
    if (hasAuthInfo(authInfoOnStore)) {
      this.authInfo = authInfoOnStore;
      return;
    }
    const data = await this.repository.fetchAuthInfo();
    this.authInfo = data;
  }
}
