import { Date } from "@/model/values/date";
import { toSnake } from "@/service/convert_service";

const properties = {
  examinationMaterialId: 0,
  fileName: "",
  originalFileName: "",
  uploadedAt: Date.empty(),
  fileData: null, // アップロード前のファイルデータ
};

export class ExaminationMaterial {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(items = properties) {
    return new ExaminationMaterial({ ...properties, ...items });
  }
  static empty() {
    return ExaminationMaterial.valueOf({});
  }

  toJson() {
    return toSnake({
      examinationMaterialId: this.examinationMaterialId,
      materialType: this.fileName,
    });
  }
}
