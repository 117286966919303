export const usage_status = {
  paid: { name: "成功" },
  error: { name: "エラー", isError: true },
};

export const type = {
  recieve_sending: { name: "入金" },
  bulk_sending: { name: "支払" },
  service_fee_payment: { name: "利用料支払" },
  charge_back: { name: "口座への戻し" },
  capital_deposit: { name: "チャージ" },
};
