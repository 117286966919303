import Vue from "vue";
import VueRouter from "vue-router";
import { authenticate } from "@/service/auth_service";
import { noAuthFlow } from "./no_auth_flow";
import { authFlow } from "./auth_flow";
import { guardInjection } from "./guard_injection";
import { LoginRepository } from "@/infrastructure/repository/login";
import { AuthInfoRepository } from "@/infrastructure/repository/auth_info";
import { InformationRepository } from "@/infrastructure/repository/information";
import { MemberRepository } from "@/infrastructure/repository/member";
import { ExaminationMaterialRepository } from "@/infrastructure/repository/examination_material";
import { MftUserRepository } from "@/infrastructure/repository/mft_user";
import { InvitationRepository } from "@/infrastructure/repository/invitation";
import { BillRepository } from "@/infrastructure/repository/bill";
import { PaymentRepository } from "@/infrastructure/repository/payment";
import { OperationLogRepository } from "@/infrastructure/repository/operation_log";

Vue.use(VueRouter);

// TODO: main.jsと同じ階層にinjectionを移動したい
export const setupRepository = {
  auth: new AuthInfoRepository(),
  loginRepository: new LoginRepository(),
};

const routes = [
  {
    path: "/",
    meta: {},
    redirect: () => (authenticate() ? "/home" : "/login"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    props: () => ({ repository: new LoginRepository() }),
  },
  {
    path: "/login-password-send-mail",
    name: "LoginPasswordSendMail",
    component: () => import("@/views/LoginPasswordSendMail.vue"),
    props: () => ({ repository: new MftUserRepository() }),
  },
  {
    // TODO: login-xxx認証済みのとき遷移できないようにガード追加
    path: "/login-password-send-mail-confirm",
    name: "LoginPasswordSendMailConfirm",
    component: () => import("@/views/LoginPasswordSendMailConfirm.vue"),
  },
  {
    // TODO: パスワード再設定メールに記載のURLからのみ遷移可能なようにガード追加
    path: "/login-password-setting",
    name: "LoginPasswordSetting",
    component: () => import("@/views/LoginPasswordSetting.vue"),
    props: (route) => ({
      token: route.query.token,
      repository: new MftUserRepository(),
    }),
  },
  {
    path: "/login-register",
    name: "LoginRegister",
    props: (route) => ({
      token: route.query.token,
      repository: new MftUserRepository(),
      invitationRepository: new InvitationRepository(),
    }),
    component: () => import("@/views/LoginRegister.vue"),
    meta: { authRequired: false },
  },
  {
    path: "/login-register-complete",
    name: "LoginRegisterComplete",
    component: () => import("@/views/LoginRegisterComplete.vue"),
    meta: { authRequired: true },
  },
  {
    path: "/reset-email",
    name: "ResetEmail",
    props: (route) => ({
      token: route.query.token,
      repository: new MftUserRepository(),
    }),
    component: () => import("@/views/ResetEmail.vue"),
    meta: { authRequired: false },
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    props: () => ({
      memberRepository: new MemberRepository(),
      billRepository: new BillRepository(),
    }),
    meta: { authRequired: true },
  },
  {
    path: "/information-list",
    name: "InformationList",
    component: () => import("@/views/InformationList.vue"),
    props: () => ({ repository: new InformationRepository() }),
    meta: { title: "お知らせ", authRequired: true },
  },
  {
    path: "/information-register",
    name: "InformationRegister",
    component: () => import("@/views/InformationRegister.vue"),
    props: () => ({ repository: new InformationRepository() }),
    meta: {
      title: "新規お知らせ登録",
      authRequired: true,
      breadcrumbs: ["お知らせ"],
    },
  },
  {
    path: "/information-edit/:id",
    name: "InformationEdit",
    component: () => import("@/views/InformationEdit.vue"),
    props: (route) => ({
      id: route.params.id,
      repository: new InformationRepository(),
    }),
    meta: {
      title: "お知らせ編集",
      authRequired: true,
      breadcrumbs: ["お知らせ"],
    },
  },
  {
    path: "/member_management-list",
    name: "MemberList",
    component: () => import("@/views/MemberList.vue"),
    props: (route) => ({
      id: route.params.id,
      repository: new MemberRepository(),
    }),
    meta: { title: "会員", authRequired: true },
  },
  {
    path: "/member_management-detail/:id",
    name: "MemberDetail",
    component: () => import("@/views/MemberDetail.vue"),
    props: (route) => ({
      id: route.params.id,
      repository: new MemberRepository(),
      fileRepository: new ExaminationMaterialRepository(),
    }),
    meta: { title: "会員詳細", authRequired: true, breadcrumbs: ["会員"] },
  },
  {
    path: "/account-list",
    name: "AccountList",
    component: () => import("@/views/AccountList.vue"),
    props: (route) => ({
      id: route.params.id,
      repository: new MftUserRepository(),
    }),
    meta: { title: "アカウント", authRequired: true },
  },
  {
    path: "/account-register",
    name: "AccountInvitation",
    component: () => import("@/views/AccountInvitation.vue"),
    props: () => ({
      repository: new InvitationRepository(),
    }),
    meta: {
      title: "アカウント招待",
      authRequired: true,
      breadcrumbs: ["アカウント"],
    },
  },
  {
    path: "/account-edit/:id",
    name: "AccountEdit",
    component: () => import("@/views/AccountEdit.vue"),
    props: (route) => ({
      id: route.params.id,
      repository: new MftUserRepository(),
    }),
    meta: {
      title: "アカウント情報の変更",
      authRequired: true,
      breadcrumbs: ["アカウント"],
    },
  },
  {
    path: "/billing-list",
    name: "BillingList",
    component: () => import("@/views/BillingList.vue"),
    props: () => ({
      repository: new BillRepository(),
    }),
    meta: { title: "請求", authRequired: true },
  },
  {
    path: "/billing-detail/:id",
    name: "BillingDetail",
    component: () => import("@/views/BillingDetail.vue"),
    props: (route) => ({
      id: route.params.id,
      repository: new BillRepository(),
    }),
    meta: {
      title: "請求詳細",
      authRequired: true,
      breadcrumbs: ["請求"],
    },
  },
  {
    path: "/billing-edit/:id",
    name: "BillingEdit",
    component: () => import("@/views/BillingEdit.vue"),
    props: (route) => ({
      id: route.params.id,
      repository: new BillRepository(),
    }),
    meta: {
      title: "請求金額の調整",
      authRequired: true,
      breadcrumbs: ["請求"],
    },
  },
  {
    path: "/billing-bulk-reconciliation",
    name: "BillingBulkReconciliation",
    component: () => import("@/views/BillingBulkReconciliation.vue"),
    props: () => ({
      repository: new BillRepository(),
    }),
    meta: { title: "一括消込", authRequired: true, breadcrumbs: ["請求"] },
  },
  {
    path: "/billing-bulk-execute",
    name: "BillingBulkExecute",
    component: () => import("@/views/BillingBulkExecute.vue"),
    props: () => ({
      repository: new BillRepository(),
    }),
    meta: { title: "一括請求", authRequired: true, breadcrumbs: ["請求"] },
  },
  {
    path: "/billing-calculate",
    name: "BillingCalculate",
    component: () => import("@/views/BillingCalculate.vue"),
    props: () => ({
      repository: new BillRepository(),
    }),
    meta: { title: "締め処理", authRequired: true, breadcrumbs: ["請求"] },
  },
  {
    path: "/user-info",
    name: "UserInfo",
    component: () => import("@/views/UserInfo.vue"),
    props: () => ({
      repository: new AuthInfoRepository(),
    }),
    meta: { title: "ログイン情報", authRequired: true },
  },
  {
    path: "/user-info-edit",
    name: "UserInfoEdit",
    component: () => import("@/views/UserInfoEdit.vue"),
    props: () => ({
      repository: new MftUserRepository(),
    }),
    meta: {
      title: "ログイン情報の編集",
      authRequired: true,
      breadcrumbs: ["ログイン情報"],
    },
  },
  {
    path: "/payment-list",
    name: "PaymentList",
    component: () => import("@/views/PaymentList.vue"),
    props: () => ({
      repository: new PaymentRepository(),
    }),
    meta: { title: "入金", authRequired: true },
  },
  {
    path: "/payment-detail/:id",
    name: "PaymentDetail",
    component: () => import("@/views/PaymentDetail.vue"),
    props: (route) => ({
      id: route.params.id,
      repository: new PaymentRepository(),
      memberRepository: new MemberRepository(),
    }),
    meta: {
      title: "入金データの変更",
      authRequired: true,
      breadcrumbs: ["入金"],
    },
  },
  {
    path: "/payment-register",
    name: "PaymentRegister",
    component: () => import("@/views/PaymentRegister.vue"),
    props: () => ({
      repository: new PaymentRepository(),
      memberRepository: new MemberRepository(),
    }),
    meta: { title: "入金データ作成", authRequired: true },
  },
  {
    path: "/workflow-list",
    name: "WorkflowList",
    component: () => import("@/views/WorkflowList.vue"),
    props: () => ({
      repository: new OperationLogRepository(),
      accountRepository: new MftUserRepository(),
    }),
    meta: { title: "処理履歴", authRequired: true },
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import("@/views/NotFound.vue"),
  },
];

guardInjection(routes);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === "NotFound") {
    if (authenticate()) next();
    else next({ path: "/login", replace: true });
  }

  if (to.meta && !to.meta.authRequired) {
    noAuthFlow(to, next);
    return;
  }

  const auth = authenticate();
  if (auth) authFlow(to, from, next);
  else noAuthFlow(to, next);
});

export default router;
