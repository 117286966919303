import { authInfoFromJson } from "@/dto/auth_info";
import Repository from "@/infrastructure/api";

const resource = "/management/me";
export class AuthInfoRepository {
  fetchAuthInfo = async (params = {}) => {
    const { data } = await Repository.get(`${resource}`, params);
    return authInfoFromJson(data);
  };
}
