import { status } from "@/const/account_status";

export class AccountStatus {
  constructor(value) {
    this.value = value;
  }

  static valueOf(value = "") {
    return new AccountStatus(value);
  }
  static empty() {
    return AccountStatus.valueOf();
  }

  toString() {
    const { value } = this;
    return status[value]?.name || "";
  }

  get isNewRegistration() {
    return this.value === status.new_registration.value;
  }
  get isInUse() {
    return this.value === status.in_use.value;
  }
  get isDeleted() {
    return this.value === status.deleted.value;
  }
}
