import { PageNation } from "@/model/form/page_nation";
import { toSnake } from "@/service/convert_service";
import { DateRange } from "../values/date_range";

const properties = {
  status: [],
  keyword: "",
  registeredAt: DateRange.empty(),
  useStartAt: DateRange.empty(),
  pageNation: PageNation.firstPage(),
  sortDirection: "desc",
  sortColumn: "member_id",
};

export class MemberListSearchForm {
  constructor({
    status,
    keyword,
    registeredAt,
    useStartAt,
    pageNation,
    sortColumn,
    sortDirection,
  }) {
    this.status = status;
    this.keyword = keyword;
    this.registeredAt = registeredAt;
    this.useStartAt = useStartAt;
    this.pageNation = pageNation;
    this.sortColumn = sortColumn;
    this.sortDirection = sortDirection;
  }

  static valueOf(items = {}) {
    return new MemberListSearchForm({ ...properties, ...items });
  }

  static empty() {
    return new MemberListSearchForm(properties);
  }

  clear() {
    const { pageNation } = this;
    return MemberListSearchForm.valueOf({ ...properties, ...{ pageNation } });
  }

  updateKeyword(value) {
    return MemberListSearchForm.valueOf({ ...this, ...{ keyword: value } });
  }

  updateStatus(value) {
    return MemberListSearchForm.valueOf({ ...this, ...{ status: value } });
  }

  updateRegisteredAt(value) {
    const registeredAt = this.registeredAt.updateDateRange(value);
    return MemberListSearchForm.valueOf({ ...this, ...{ registeredAt } });
  }

  updateUseStartAt(value) {
    const useStartAt = this.useStartAt.updateDateRange(value);
    return MemberListSearchForm.valueOf({ ...this, ...{ useStartAt } });
  }

  updateNumberOfData(value) {
    const pageNation = this.pageNation.updateNumberOfData(value);
    return new MemberListSearchForm({ ...this, ...{ pageNation } });
  }

  updatePage(value) {
    const pageNation = this.pageNation.updatePage(value);
    return new MemberListSearchForm({ ...this, ...{ pageNation } });
  }
  updateDataCount(value) {
    const pageNation = this.pageNation.updateDataCount(value);
    return new MemberListSearchForm({ ...this, ...{ pageNation } });
  }

  updateSortDirection() {
    const value = this.sortDirection === "desc" ? "asc" : "desc";
    return MemberListSearchForm.valueOf({
      ...this,
      ...{ sortDirection: value },
    });
  }

  updateSortColumn(value) {
    return MemberListSearchForm.valueOf({ ...this, ...{ sortColumn: value } });
  }

  toJson(isCsv = false) {
    const {
      status,
      registeredAt,
      useStartAt,
      keyword,
      pageNation,
      sortColumn,
      sortDirection,
    } = this;
    const params = isCsv ? {} : pageNation.toJson();
    if (status.length) params.status = status;
    if (keyword) params.keyword = keyword;
    if (registeredAt.isValid) {
      params.application_date_from = registeredAt.start.toParam();
      params.application_date_to = registeredAt.end.toParam();
    }
    if (useStartAt.isValid) {
      params.use_start_date_from = useStartAt.start.toParam();
      params.use_start_date_to = useStartAt.end.toParam();
    }
    params.sort_direction = sortDirection;
    params.sort_column = sortColumn;

    return toSnake(params);
  }
}
