import { Date } from "@/model/values/date";
import { toSnake } from "@/service/convert_service";
import { Bank } from "../bank/bank";
import { Branch } from "../bank/branch";
import { Employee } from "../employee/employee";
import { RatePlan } from "../rate_plan/rate_plan";
import { AmountOfMoney } from "../values/amount_of_money";
import { ExaminationMaterial } from "./examination_material";
import { MemberStatus } from "./member_status";

const properties = {
  id: 0,
  companyName: "",
  displayCompanyName: "",
  zipCode: "",
  address1: "",
  address2: "",
  address3: "",
  address4: "",
  corporateNumber: "",
  corporatePhoneNumber: "",
  registeredAt: Date.empty(),
  leavedAt: Date.empty(),
  useStartAt: Date.empty(),
  useStopAt: Date.empty(),
  isUseWorkflow: "",
  numberOfApprovalStep: 0,
  defaultDeadlineDays: "",
  numberOfEmployees: 0,
  lastUseDate: Date.empty(),
  status: MemberStatus.empty(),
  primaryUser: Employee.empty(),
  balance: AmountOfMoney.empty(),
  accountType: {},
  accountNumber: "",
  recipientNameKana: "",
  outstandingMonths: 0,
  paymentCount: 0,
  billDiscountAmount: AmountOfMoney.empty(),
  outstandingServiceBill: AmountOfMoney.empty(),
  serviceBill: AmountOfMoney.empty(),
  totalBill: AmountOfMoney.empty(),
  examinationHistories: [],
  examinationMaterials: [],
  memo: "",
  ratePlan: RatePlan.empty(),
  bank: Bank.empty(),
  branch: Branch.empty(),
  approvalComment: "",
};

export class Member {
  constructor(items = properties) {
    Object.assign(this, items);
  }

  static valueOf(items = properties) {
    return new Member({ ...properties, ...items });
  }
  static empty() {
    return Member.valueOf({});
  }

  toJson() {
    return toSnake({
      managementMemo: this.memo,
      examinationMaterials: this.examinationMaterials.map((material) =>
        material.toJson(),
      ),
    });
  }

  updateStatus(value) {
    return new Member({ ...this, status: MemberStatus.valueOf(value) });
  }

  updateMemo(value) {
    return new Member({ ...this, memo: value });
  }
  updateFileName(name, id) {
    const newFiles = this.examinationMaterials.map((file) =>
      file.examinationMaterialId === id
        ? ExaminationMaterial.valueOf({ ...file, fileName: name })
        : file,
    );

    return new Member({
      ...this,
      examinationMaterials: newFiles,
    });
  }
  updateFile(files) {
    const newFiles = Array.from(files).map((file) =>
      ExaminationMaterial.valueOf({
        originalFileName: file.name,
        fileData: file,
      }),
    );
    return new Member({
      ...this,
      examinationMaterials: [...this.examinationMaterials, ...newFiles],
    });
  }
  updateApprovalComment(value) {
    return new Member({ ...this, approvalComment: value });
  }
}
