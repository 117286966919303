import { Account } from "@/model/account/account";
import { AccountStatus } from "@/model/account/account_status";
import { Permission } from "@/model/values/permission";
import { toCamel } from "@/service/convert_service";

export const mftUserFromJson = (json) => {
  const {
    mftId,
    lastName,
    firstName,
    lastNameKana,
    firstNameKana,
    email,
    status,
    permission,
    employeeNumber,
    departmentName,
    cellphoneNumber,
  } = toCamel(json);

  return Account.valueOf({
    mftId,
    lastName,
    lastNameKana,
    firstName,
    firstNameKana,
    email,
    status: AccountStatus.valueOf(status),
    permission: Permission.valueOf({
      functionPermissions: permission.functions_permission,
    }),
    employeeNumber,
    departmentName,
    cellphoneNumber,
  });
};
