import Repository from "@/infrastructure/api";

const resource = "management/login";
export class LoginRepository {
  register = async (payload) => {
    const { data } = await Repository.post(resource, payload);
    return { jwt: data["jwt_token"] };
  };

  logout = async () => {
    await Repository.post("management/logout");
  };
}
