import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import rules from "./validates";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VueCompositionApi from "@vue/composition-api";
import { reload } from "@/service/router_service";

Vue.use(VueLoading);
Vue.use(VueCompositionApi);
Vue.config.productionTip = false;
Vue.config.errorHandler = (err) => {
  if (err.name === "ChunkLoadError") reload();
};

rules.initialize();
document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount();
  document.body.appendChild(app.$el);
});
