import { billFromJson } from "@/dto/bill";
import Repository from "@/infrastructure/api";
import { BillingList } from "@/model/billing/billing_list";

// const resource = "/management/bill";
const resources = "/management/bills";
export class BillRepository {
  fetchBillingList = async (params) => {
    const { data } = await Repository.get(`${resources}`, { params });

    const dataCount = data["data_count"];
    const currentBillList = data["current_bill_list"];
    const listItems = currentBillList.map((json) => billFromJson(json));
    return BillingList.valueOf({ dataCount, listItems });
  };
  fetchNotificationCount = async () => {
    const { data } = await Repository.get(`${resources}/alerts`);
    return {
      unpaidCount: data["unpaid_count"],
      unbillingCount: data["unbilling_count"],
      uncalculatedCount: data["uncalculated_count"],
    };
  };
  fetchBillingDetail = async (id) => {
    const { data } = await Repository.get(`${resources}/${id}`);
    return billFromJson(data);
  };
  bill = async (payload) => {
    await Repository.post(`${resources}/execute`, payload);
  };
  reconcile = async (payload) => {
    await Repository.post(`${resources}/reconcile`, payload);
  };
  calculate = async () => await Repository.post(`${resources}/calculate`);
  postAdjustment = async (payload, billId) =>
    await Repository.post(`${resources}/${billId}/adjustments`, payload);
  fetchServiceBillCsvData = async (params) => {
    const { data } = await Repository.post(
      `${resources}/export_service_bills_csv?${params}`,
    );
    return data;
  };
  fetchServiceBillPaymentCsvData = async (params) => {
    const { data } = await Repository.post(
      `${resources}/export_service_bill_payments_csv?${params}`,
    );
    return data;
  };
}
