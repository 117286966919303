import { PermissionError } from "@/error/permission_error";
import { UnauthorizedError } from "@/error/unauthorize_error";
import { authenticate } from "@/service/auth_service";
import {
  billingExecutePermissionCheck,
  billingReconciliationPermissionCheck,
  deletePermissionCheck,
  readPermissionCheck,
  registerPermissionCheck,
  updatePermissionCheck,
} from "@/service/permission_service";
import store from "@/store";

export const tokenGuard = (to) => {
  if (to.path === "/login-register" && !to.query.token) {
    return false;
  }
  return true;
};

export const readPermissionGuard = (type) => {
  const { functionPermissions } = store.state.auth.authInfo.account.permission;
  const result = readPermissionCheck(type, functionPermissions);
  if (result) return null;

  return new PermissionError();
};

export const registerPermissionGuard = (type) => {
  const { functionPermissions } = store.state.auth.authInfo.account.permission;
  const result = registerPermissionCheck(type, functionPermissions);
  if (result) return null;

  return new PermissionError();
};

export const updatePermissionGuard = (type) => {
  const { functionPermissions } = store.state.auth.authInfo.account.permission;
  const result = updatePermissionCheck(type, functionPermissions);
  if (result) return null;

  return new PermissionError();
};

export const deletePermissionGuard = (type) => {
  const { functionPermissions } = store.state.auth.authInfo.account.permission;
  const result = deletePermissionCheck(type, functionPermissions);
  if (result) return null;

  return new PermissionError();
};

export const billingExecutionPermissionGuard = () => {
  const { functionPermissions } = store.state.auth.authInfo.account.permission;
  const result = billingExecutePermissionCheck(functionPermissions);
  if (result) return null;

  return new PermissionError();
};

export const billingReconciliationPermissionGuard = () => {
  const { functionPermissions } = store.state.auth.authInfo.account.permission;
  const result = billingReconciliationPermissionCheck(functionPermissions);
  if (result) return null;

  return new PermissionError();
};

export const authGuard = () => {
  if (authenticate()) return null;

  return new UnauthorizedError();
};

export const tryPermissionGuard = (to) => {
  const { guard } = to.meta;
  if (guard.length) {
    const errors = guard.map((f) => f()).filter((v) => v);
    if (errors.length) {
      throw errors[0];
    }
  }
};

export const tryAuthenticateGuard = (to) => {
  const { guard } = to.meta;
  if (guard.length) {
    const errors = guard.map((f) => f()).filter((v) => v);
    if (errors.length) {
      throw errors[0];
    }
  }
};
