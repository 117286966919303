const properties = {
  dataCount: 0,
  announce: [],
  // pushNotification: []
};

export class InformationList {
  constructor({
    announce,
    // pushNotification
    dataCount,
  }) {
    this.announce = announce;
    // this.pushNotification = pushNotification
    this.dataCount = dataCount;
  }

  static valueOf(items = properties) {
    return new InformationList({ ...properties, ...items });
  }
  static empty() {
    return InformationList.valueOf({});
  }
}
