// import { toSnake } from "@/service/convert_service";
import { Date } from "@/model/values/date";

const properties = {
  id: 0,
  employeeNumber: "",
  lastName: "",
  firstName: "",
  lastNameKana: "",
  firstNameKana: "",
  email: "",
  birthday: Date.empty(),
  cellphoneNumber: "",
};
export class Employee {
  constructor(items) {
    const item = { ...properties, ...items };
    Object.assign(this, item);
  }

  static valueOf(item = properties) {
    return new Employee(item);
  }

  static empty() {
    return Employee.valueOf();
  }

  toJson() {
    return this;
  }

  toString() {
    return this.lastName + this.firstName;
  }

  updateEmployeeNumber(value) {
    // TODO: validateなど
    const properties = { ...this, ...{ employeeNumber: value } };
    return Employee.valueOf(properties);
  }
  updateFirstName(value) {
    // TODO: validateなど
    const properties = { ...this, ...{ firstName: value } };
    return Employee.valueOf(properties);
  }
  updateFirstNameKana(value) {
    // TODO: validateなど
    const properties = { ...this, ...{ firstNameKana: value } };
    return Employee.valueOf(properties);
  }
  updateLastName(value) {
    // TODO: validateなど
    const properties = { ...this, ...{ lastName: value } };
    return Employee.valueOf(properties);
  }
  updateLastNameKana(value) {
    // TODO: validateなど
    const properties = { ...this, ...{ lastNameKana: value } };
    return Employee.valueOf(properties);
  }
  updateEmail(value) {
    // TODO: validateなど
    const properties = { ...this, ...{ email: value } };
    return Employee.valueOf(properties);
  }

  updateCellphoneNumber(value) {
    const properties = { ...this, ...{ cellphoneNumber: value } };
    return Employee.valueOf(properties);
  }

  updateBirthday(value) {
    const properties = { ...this, ...{ birthday: Date.valueOf(value) } };
    return Employee.valueOf(properties);
  }

  get employeeNameFull() {
    const { lastName, lastNameKana, firstName, firstNameKana } = this;
    return `${lastName}${firstName}（${lastNameKana}${firstNameKana}）`;
  }

  // toUpdateParams() {
  //   const { employeeNumber, lastName, firstName, lastNameKana, firstNameKana } =
  //     this;
  //   return toSnake({
  //     employeeNumber,
  //     lastName,
  //     lastNameKana,
  //     firstName,
  //     firstNameKana,
  //   });
  // }

  // toEditParams() {
  //   const { employeeNumber, lastName, firstName, lastNameKana, firstNameKana } =
  //     this;
  //   return toSnake({
  //     employeeNumber,
  //     lastName,
  //     lastNameKana,
  //     firstName,
  //     firstNameKana,
  //   });
  // }
}
