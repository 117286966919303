import { Information } from "@/model/information/information";
import { Date } from "@/model/values/date";
import { toCamel } from "@/service/convert_service";

export const informationListItemFromJson = (json) => {
  const {
    informationId,
    message,
    isImportant,
    type,
    postPeriodFrom,
    postPeriodTo,
    targetId,
    target,
    isRead,
  } = toCamel(json);

  return Information.valueOf({
    id: informationId,
    message,
    isImportant,
    type,
    postPeriodFrom: Date.valueOf(postPeriodFrom),
    postPeriodTo: Date.valueOf(postPeriodTo),
    targetId,
    target,
    isRead,
  });
};
